
import axios from 'axios'

//运行环境  ：本地环境(development)/线上（生产）环境(production)
const isPro = process.env.NODE_ENV === 'development'
// development
 
//创建一个axios的实例
const request = axios.create({
    //请求的基础路径
    baseURL:isPro ? 'http://admin.bayichuangdian.com/api/index/' : 'http://admin.bayichuangdian.com/api/index/',
    //超时时间
    timeout:2000
})
// //请求拦截：每一次发请求都会做的事  
// request.interceptors.request.use((config)=>{
//     nprogress.start()
//     // 前后端鉴权   令牌
//     let token = localStorage.getItem('adminToken')
//     if(token){
//         //请求头中添加token、
//         config.headers['Authorization'] =  token
//     }
//     return config
// },err=>{
//     return Promise.reject(err)
// })

//相应拦截：每一次接口返回数据时都会做的事
request.interceptors.response.use((res)=>{
     return res.data
},err=>{
    if(err.response && err.response.status){
        //错误请求的状态码
        let status = err.response.status
        if(status === 400){
          console.log('参数错误')
        }
        if(status === 401){
          console.log('登录过期')
        }
        if(status === 403){
          console.log('没有权限')
        }
        if(status === 404){
          console.log('接口路径错误')
        }
        if(status === 500){
          console.log('服务器出错')
        }
        if(status === 503){
            console.log('服务器在维护中')
        }
        return Promise.reject(err)
    }
})
export default request