<template>
  <div id="home">
    <Header id="Header" class="home_header" :background="background"></Header>
    <div class="top_banner_box">
      <swiper
        ref="mySwiper"
        style="width: 100%; height: 100%"
        :options="Optionshometop"
        class="swiper"
      >
        <swiper-slide
          class="border_box"
          v-for="(item, index) in homeData.banner"
          :key="index"
        >
          <img class="my_cover" :src="item.image" alt="" />
        </swiper-slide>
        <div class="swiper-pagination" slot="pagination"></div>
      </swiper>
    </div>
    <div class="two_banner_box">
      <swiper
        ref="mySwiper"
        style="width: 100%; height: 100%"
        :options="Optionshometwo"
      >
        <swiper-slide>
          <div class="second_left_title" v-if="homeData.left">
            {{ homeData.left.title }}
          </div>
          <div class="second_left_info" v-if="homeData.left">
            {{ homeData.left.info }}
          </div>
        </swiper-slide>
        <swiper-slide
          class="right_back_slide"
          v-for="(item, index) in homeData.right"
          :key="index"
          :style="{ backgroundImage: 'url(' + item.background + ')' }"
        >
          <div class="right_back_slidediv">
            <div class="slidecenter">
              <div class="title">{{ item.title }}</div>
              <div class="subtitle">{{ item.subtitle }}</div>
              <div class="slogan">{{ item.slogan }}</div>
            </div>
          </div>
        </swiper-slide>
      </swiper>
    </div>
    <Ordinary style="margin-top: 98px"></Ordinary>
    <div class="classification_box">
      <div class="classification_center_box">
        <div class="left">
          <div
            class="left_item"
            v-for="(item, index) in homeData.scene"
            :key="index"
            @click="classification_left(index)"
            :class="
              classification_left_num == index ? 'activeright_back_slide' : ''
            "
          >
            <div class="left_left_radius">
              <img :src="item.icon" class="left_left_radius_icon" alt="" />
            </div>
            <div class="left_left_text">
              <div class="title">{{ item.title }}</div>
              <div class="min_title">{{ item.subtitle }}</div>
            </div>
          </div>
        </div>
        <div class="right">
          <swiper
            ref="mySwiper"
            style="width: 100%; height: 100%"
            :options="Optionshomethree"
            v-if="classification_arr.length > 0"
          >
            <swiper-slide
              class="classification_right_back_slide"
              v-for="(item, index) in classification_arr"
              :key="index"
              :style="{ backgroundImage: 'url(' + item + ')' }"
            >
            </swiper-slide>
            <div class="swiper-pagination" slot="pagination"></div>
          </swiper>
        </div>
      </div>
    </div>
    <Ordinary
      :enTitle="enTitle"
      :zhTitle="zhTitle"
      style="margin-bottom: 46px; margin-top: -50px"
    ></Ordinary>
    <div class="video_list_box">
      <List :types="1" :carList="videoData"></List>
      <div class="look_more" @click="video_more">查看更多></div>
    </div>
    <div
      class="progressive_box"
      v-if="homeData.other"
      :style="{ backgroundImage: 'url(' + homeData.other.trade_in_image + ')' }"
      @click="totradeIn"
    >
      <div class="zh">以旧换新</div>
      <div class="en">PORFRESSIVE</div>
    </div>
    <div class="brandDynamics">
      <Ordinary
        :enTitle="enTitle1"
        :zhTitle="zhTitle1"
        style="margin-top: 98px; margin-bottom: 92px"
      ></Ordinary>
      <div class="swiper_my_box">
        <swiper
          style="width: 1200px; margin: 0 auto; height: 100%"
          :options="OptionsbrandDynamics"
        >
          <swiper-slide
            style="height: 601px"
            class="border_box"
            v-for="(item, index) in homeData.brand"
            :key="index"
          >
            <div
              class="Brand_item"
              @mouseenter="Brand_itementer(index)"
              @mouseleave="Brand_itemleave"
              @click="tobeandLise"
            >
              <div class="item_min_box">
                <!-- :style="{
                  backgroundImage:
                    Brand_itemFlag === index ? 'url(' + item.cover + ')' : '',
                }" -->
                <img :src="item.cover" class="sliedItem_img" alt="" />
                <div class="sliedItem_text_box">
                  <div class="title">{{ item.title }}</div>
                  <div class="info">{{ item.info }}</div>
                  <div class="more_anddate">
                    <div class="more">| 查看更多</div>
                    <div class="date">
                      <span class="mothe">{{
                        item.month.replace("-", "/")
                      }}</span
                      ><span class="l">l</span
                      ><span class="year">{{ item.year }}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </swiper-slide>
          <div class="swiper-pagination" slot="pagination"></div>
        </swiper>
      </div>
    </div>
    <HmoeBottomView :other="other"></HmoeBottomView>
  </div>
</template>

<script>
import Header from "../../components/Header";
import Ordinary from "../../components/ordinary";
import Spot from "../../components/spot";
import LineTitle from "../../components/lineTitle";
import Crumbs from "../../components/crumbs";
import List from "../../components/list";
import HmoeBottomView from "./HmoeBottomView";
import { getHomeData, getVideoList } from "../../http/api";
export default {
  name: "",
  props: {},
  components: {
    Header,
    Ordinary,
    Spot,
    LineTitle,
    Crumbs,
    List,
    HmoeBottomView,
  },
  data() {
    var that = this;
    return {
      background: { "background-color": "transparent" },
      enTitle: "Video Center",
      zhTitle: "视频中心",
      enTitle1: "Brand dynamics",
      zhTitle1: "品牌动态",
      videoData: [],
      swiper: null,
      swiper1: null,
      topscroll: 0,
      other: {},
      OptionsbrandDynamics: {
        slidesPerView: 3,
        spaceBetween: 19,
        pagination: {
          el: ".swiper-pagination",
          clickable: true,
        },
      },
      Brand_itemFlag: -1,
      message_back: {
        backgroundImage: require("../../assets/img/message_back.png"),
      },
      classification_arr: [],
      Optionshomethree: {
        slidesPerView: 1,
        pagination: {
          el: ".swiper-pagination",
          clickable: true,
        },
        on:{
          click:function (){
            window.location.href = that.homeData.scene[that.classification_left_num].link
          }
        }
      },
      OptionshometwoActive: null,
      Optionshometwo: {
        slidesPerView: 2,
        mousewheel: true,
        freeMod: true,
        releaseOnEdges: true,
        on: {
          init: function (swiper) {
            that.swiper1 = this;
          },
          slideChangeTransitionStart: function () {
            that.OptionshometwoActive = this.activeIndex;
          },
        },
      },
      OptionshometopActive: null,
      Optionshometop: {
        slidesPerView: 1,
        direction: "vertical",
        mousewheel: true,
        // freeMode: true,
        sensitivity: 0.1,
        releaseOnEdges: true,
        pagination: {
          el: ".swiper-pagination",
          clickable: true,
        },
        on: {
          init: function (swiper) {
            that.swiper = this;
          },
          slideChangeTransitionStart: function () {
            that.OptionshometopActive = this.activeIndex;
          },
        },
      },
      homeData: {},
      classification_left_num: 0,
    };
  },
  methods: {
    totradeIn() {
      // 去以旧换新
      this.$router.push({
        path: "/tradeIn",
      });
    },
    tobeandLise() {
      this.$router.push({
        path: "/BrandList",
      });
    },
    Brand_itementer(index) {
      this.Brand_itemFlag = index;
    },
    Brand_itemleave() {
      this.Brand_itemFlag = -1;
    },
    getVideoData() {
      getVideoList({
        page: 1,
        limit: 3,
      }).then((res) => {
        this.videoData = res.data.data;
      });
    },
    classification_left(index) {
      this.classification_left_num = index;
      this.classification_arr = this.homeData.scene[
        this.classification_left_num
      ].image;
    },
    scrollHandle(e) {
      let top = e.srcElement.scrollingElement.scrollTop; // 获取页面滚动高度
      this.topscroll = top;
    },
    video_more() {
      this.$router.push({
        path: "/videoList",
      });
    },

    // 监听鼠标滚动事件控制swiper鼠标滚动事件
    windowAddMouseWheel() {
      var that = this;
      var scrollFunc = function (e) {
        e = e || window.event;
        if (e.wheelDelta && that.swiper && that.swiper1) {
          //判断浏览器IE，谷歌滑轮事件
          if (e.wheelDelta > 0) {
            //当滑轮向上滚动时
            // alert("滑轮向上滚动");
            if (that.OptionshometwoActive == 0) {
              that.swiper1.mousewheel.disable();
            }
            if (
              that.OptionshometwoActive <= that.homeData.right.length - 1 &&
              that.OptionshometwoActive > 0
            ) {
              that.swiper1.mousewheel.enable();
            }
            if (
              that.OptionshometopActive <= that.homeData.banner.length - 1 &&
              that.OptionshometopActive > 0
            ) {
              that.swiper.mousewheel.enable();
            }
          }
          if (e.wheelDelta < 0) {
            //当滑轮向下滚动时
            // alert("滑轮向下滚动");
            if (that.OptionshometwoActive >= 0) {
              that.swiper1.mousewheel.enable();
            }
            if (that.OptionshometwoActive == that.homeData.right.length - 1) {
              that.swiper1.mousewheel.disable();
            }
            if (that.OptionshometopActive == that.homeData.banner.length - 1) {
              that.swiper.mousewheel.disable();
            }
          }
        } else if (e.detail && that.swiper && that.swiper1) {
          //Firefox滑轮事件
          if (e.detail > 0) {
            //当滑轮向上滚动时
            // alert("滑轮向上滚动");
            if (that.OptionshometwoActive == 0) {
              that.swiper1.mousewheel.disable();
            }
            if (
              that.OptionshometwoActive <= that.homeData.right.length - 1 &&
              that.OptionshometwoActive > 0
            ) {
              that.swiper1.mousewheel.enable();
            }
          }
          if (e.detail < 0) {
            //当滑轮向下滚动时
            // alert("滑轮向下滚动");
            if (that.OptionshometwoActive >= 0) {
              that.swiper1.mousewheel.enable();
            }
            if (that.OptionshometwoActive == that.homeData.right.length - 1) {
              that.swiper1.mousewheel.disable();
            }
          }
        }
      };
      //给页面绑定滑轮滚动事件
      if (document.addEventListener) {
        document.addEventListener("DOMMouseScroll", scrollFunc, false);
      }
      //滚动滑轮触发scrollFunc方法
      window.onmousewheel = document.onmousewheel = scrollFunc;
    },
  },
  mounted() {
    this.windowAddMouseWheel();
    this.getVideoData();
    this.$nextTick(function () {
      window.addEventListener("scroll", this.scrollHandle); //绑定页面滚动事件
    });
    getHomeData().then((res) => {
      // console.log(res);
      this.other = res.data.other;
      this.homeData = res.data;
      this.classification_arr =
        res.data.scene[this.classification_left_num].image;
    });
  },
  destroyed() {
    this.swiper = null;
    this.swiper1 = null;
  },
  watch: {
    topscroll(val) {
      // console.log(val)
      //2125
      if (val > 125) {
        this.background = {
          "background-color": "#0A513C",
          position: "fixed",
          top: "0",
        };
      }
      if (val == 125 || val < 125) {
        this.background = { "background-color": "transparent" };
      }
      if (val > 2125) {
        $(".video_list_box #list").addClass("animation1");
      }
      // 2750
      if (val > 2750) {
        $(".progressive_box ").addClass("animation2");
      }
      if (val > 3153) {
        $(".progressive_box .zh").addClass("animation3");
        $(".progressive_box .en").addClass("animation3");
      }
      if (val > 5090) {
        $(".store_image_one img").addClass("animation4");
      }
      if (val > 5467) {
        $(".store_image_tow_box ").addClass("animation4");
      }
    },
  },
};
</script>

<style scoped lang='scss'>
#home {
  width: 100%;
  position: relative;
  background-image: url("../../assets/img/home_back1.png");
  background-size: 953px 1080px;
  background-position-y: 1063px;
  background-repeat: no-repeat;
  .home_header {
    position: absolute;
    top: 0;
    z-index: 99;
    width: 100%;
  }
  .top_banner_box {
    width: 100%;
    // height: 1080px;
    // height: 56.25vw;
    // height: 50.52vw;
    height: 100vh;
  }
  .two_banner_box {
    width: 100%;
    // height: 1080px;
    height: 56.25vw;
    .second_left_title {
      // width: 807px;
      // width: 42.031vw;
      width: 26.77vw;
      font-size: 50px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #005a3f;
      margin: 0 auto;
      // margin-top: 278px;
      margin-top: 14.47vw;
    }
    .second_left_info {
      // width: 514px;
      width: 26.77vw;
      font-size: 16px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #666666;
      line-height: 30px;
      margin: 0 auto;
      // margin-top: 102px;
      // margin-top: 102px;
      margin-top: 5%;
      white-space: pre-wrap;
    }
    .right_back_slide {
      background-repeat: no-repeat;
      background-size: cover;
      display: flex;
      justify-content: center;
      align-items: center;
      .right_back_slidediv {
        // width: 694px;
        width: 36.14vw;
        // height: 794px;
        height: 41.35vw;
        transition: all 0.3s linear;
        background: transparent;
        display: flex;
        justify-content: center;
        align-items: center;
        .slidecenter {
          // width: 602px;
          width: 31.35vw;
          height: 398px;
          .title {
            font-size: 60px;
            font-family: Microsoft YaHei;
            font-weight: bold;
            color: #005a3f;
            text-align: center;
            margin-top: 80px;
          }
          .subtitle {
            font-size: 30px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #333333;
            text-align: center;
            margin: 26px 0 144px 0;
          }
          .slogan {
            width: 100%;
            height: 59px;
            border: 1px solid #005a3f;
            border-radius: 26px;
            font-size: 24px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            line-height: 59px;
            color: #005a3f;
            text-align: center;
          }
        }
      }
    }
    .right_back_slide:hover {
      .right_back_slidediv {
        // width: 794px;
        width: 41.3554vw;
        // height: 894px;
        height: 41.35vw;
        transition: all 0.3s linear;
        background: rgba(255, 255, 255, 0.7);
      }
    }
  }
  .classification_box {
    width: 100%;
    // height: 765px;
    height: 760px;
    background-image: url("../../assets/img/tradein_back1.png");
    // background-position-x: 760px;
    background-position-x: 650px;
    background-position-y: -300px;
    background-repeat: no-repeat;
    background-size: cover;
    padding-top: 106px;
    .classification_center_box {
      width: 1200px;
      height: 619px;
      margin: 0 auto;
      box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
      display: flex;

      .left {
        width: 329px;
        height: 100%;
        background: #fff;
        overflow-y: auto;
        .left_item {
          width: 100%;
          height: 101px;
          font-size: 24px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #333333;
          margin-top: 40px;
          display: flex;
          cursor: pointer;
          .left_left_radius {
            width: 79px;
            height: 79px;
            border-radius: 50%;
            border: 1px solid rgba(0, 0, 0, 0.1);
            margin: 9px 0 9px 41px;
            background: #fff;
            display: flex;
            justify-content: center;
            align-items: center;
            .left_left_radius_icon {
              max-height: 100%;
              max-width: 100%;
            }
          }
          .left_left_text {
            width: 97px;
            margin: 20px 0 20px 22px;
            .title {
              font-size: 24px;
              font-family: Microsoft YaHei;
              font-weight: 400;
              color: #333333;
            }
            .min_title {
              font-size: 15px;
              font-family: Microsoft YaHei;
              font-weight: 400;
              color: #666666;
              margin-top: 9px;
            }
          }
        }
        .activeright_back_slide {
          background-image: url("../../assets/img/message_back.png");
          background-repeat: no-repeat;
          background-size: contain;
        }
      }
      .right {
        width: 872px;
        height: 100%;
        .classification_right_back_slide {
          // background-size: contain;
          background-size: cover;
          background-position: center;
          background-repeat: no-repeat;
        }
      }
    }
  }
  .video_list_box {
    width: 100%;
    height: 600px;
    background-image: url("../../assets/img/home_video_back.png");
    background-size: cover;
    .look_more {
      width: 150px;
      height: 50px;
      border: 1px solid #005a3f;
      border-radius: 25px;
      cursor: pointer;
      margin: 0 auto;
      margin-top: 9px;
      font-size: 18px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #005a3f;
      line-height: 50px;
      text-align: center;
    }
  }
  .progressive_box {
    cursor: pointer;
    width: 100%;
    // height: 1080px;
    height: 56.25vw;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    background-repeat: no-repeat;
    background-size: cover;
    .zh {
      font-size: 116px;
      font-family: Source Han Sans CN;
      font-weight: bold;
      color: #ffffff;
      line-height: 107px;
      text-align: center;
      text-shadow: 0px 5px 10px rgba(0, 0, 0, 0.57);
    }
    .en {
      font-size: 70px;
      font-family: FZYanSongS-R-GB;
      font-weight: bold;
      color: #fdfefd;
      text-align: center;
      line-height: 80px;
      margin-top: 38px;
      text-shadow: 0px 7px 17px rgba(0, 0, 0, 0.19);
    }
  }
  .brandDynamics {
    width: 100%;
    background-image: url("../../assets/img/brandDynamics_back.png");
    background-size: cover;
    margin-bottom: 95px;
    .swiper_my_box {
      width: 100%;
      height: 701px;
      .Brand_item:hover {
        transition: all 0.3s linear;
        .item_min_box {
          // box-shadow: none;
          // border: none;
          transition: all 0.3s linear;
          background: #1d4834;
          .sliedItem_img {
            height: 305px;
            object-fit: cover;
            box-sizing: border-box;
            transition: all 0.3s linear;
          }
          .sliedItem_text_box {
            background: #1d4834;
            height: 225px;
            margin-top: 14px;
            transition: all 0.3s linear;
            .title {
              transition: all 0.3s linear;
              color: #fff;
            }
            .info {
              transition: all 0.3s linear;
              color: #fff;
            }
            .more_anddate {
              .more {
                transition: all 0.3s linear;
                color: #fff;
              }
              .l {
                transition: all 0.3s linear;
                color: #fff;
              }
              .date {
                transition: all 0.3s linear;
                color: #fff;
              }
            }
          }
        }
      }
      .Brand_item {
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        transition: all 0.3s linear;
        background: #ffffff;
        .item_min_box {
          width: 100%;
          border: 1px solid rgba(0, 0, 0, 0.1);
          box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
          background: #fff;
          transition: all 0.3s linear;
          .sliedItem_img {
            width: 100%;
            height: 0;
          }
          .sliedItem_text_box {
            background: #fff;
            height: 225px;
            transition: all 0.3s linear;
            margin-bottom: 50px;
            .title {
              width: 355px;
              font-size: 20px;
              font-family: Microsoft YaHei;
              font-weight: 400;
              color: #333333;
              line-height: 30px;
              margin: 0 auto;
              min-height: 51px;
              transition: all 0.3s linear;
            }
            .info {
              width: 343px;
              font-size: 14px;
              font-family: Microsoft YaHei;
              font-weight: 300;
              color: #666666;
              transition: all 0.3s linear;
              margin: 35px auto 46px auto;
              min-height: 53px;
              line-height: 19px;
            }
            .more_anddate {
              width: 343px;
              height: 36px;
              transition: all 0.3s linear;
              margin: 0 auto;
              display: flex;
              justify-content: space-between;
              align-items: center;
              .more {
                font-size: 14px;
                font-family: Adobe Heiti Std;
                transition: all 0.3s linear;
                font-weight: normal;
                color: #333333;
                cursor: pointer;
              }
              .date {
                color: #035a3f;
                transition: all 0.3s linear;
                display: flex;
                .mothe {
                  font-size: 40px;
                  font-family: Microsoft YaHei;
                  font-weight: bold;
                }
                .l {
                  width: 1px;
                  height: 9px;
                  display: block;
                  margin: 30px 5px 30px 5px;
                }
                .year {
                  font-size: 20px;
                  font-family: Microsoft YaHei;
                  font-weight: 400;
                  display: block;
                  margin-top: 20px;
                  margin-left: 10px;
                }
              }
            }
          }
        }
      }
    }
  }
}
</style>
