<template>
  <div class="lineTitle">
    <!-- 中文底部带线的title 组件 -->
    <div class="line_zh">{{line_zh}}</div>
    <div class="line"></div>
  </div>
</template>

<script>
export default {
  name: "",
  props: {
      line_zh:{
          type:String,
          default:'产品参数'
      }
  },
};
</script>

<style scoped lang='scss'>
.lineTitle {
  width: 1200px;
  margin: 0 auto;
  height: 40px;
  .line_zh {
    font-size: 26px;
    font-family: PingFang SC;
    font-weight: 400;
    line-height: 27px;
    color: #666666;
    margin-bottom: 10px;
    text-align: center;
  }
  .line {
    width: 62px;
    height: 3px;
    background: #005a3f;
    margin: 0 auto;
  }
}
</style>