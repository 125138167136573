<template>
  <div class="crumbs">
    <!-- 面包屑 -->
    <div class="center_crumbs">
      <div
        :class="index == index_active ? 'second_item_active' : 'second_item'"
        v-for="(item, index) in crumbs"
        :key="index"
        @click="select(index)"
        :style="marginleft"
      >
        {{ item.name }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "",
  props: {
    crumbs: {
      type: Array,
    },
    crumbs_active:{
      type:String | Number,
      default:0
    },
    marginleft:{
      type:Object,
      default:()=>{
         return { "margin-right": "" };
      }
    }
  },
  data() {
    return {
     index_active: localStorage.getItem('crumbs_active') ? localStorage.getItem('crumbs_active') :  0,
    };
  },
  methods: {
    select(index){
      this.$parent.crumbs_active = index
      this.index_active = index
    }
  },
  watch:{
    crumbs_active(val){
      this.index_active = val
    }
  },
  destroyed(){
    localStorage.removeItem('crumbs_active')
  }
};
</script>

<style scoped lang='scss'>
.crumbs {
  width: 100%;
  height: 83px;
  border-bottom: 1px solid #e5e5e5;
  .center_crumbs {
    width: 1200px;
    // width: 62.5vw;
    height: 100%;
    margin: 0 auto;
    display: flex;
    .second_item {
      height: 100%;
      margin-right: 71px;
      line-height: 83px;
      border-bottom: 2px solid transparent;
      font-size: 18px;
      font-family: PingFang SC;
      font-weight: 400;
      color: #666666;
      cursor: pointer;
    }
    .second_item_active {
      height: 100%;
      line-height: 83px;
      margin-right: 71px;
      border-bottom: 2px solid #cb8956;
      font-size: 18px;
      font-family: PingFang SC;
      font-weight: 400;
      color: #cb8956;
      cursor: pointer;
    }
  }
}
</style>