<template>
  <div id="HmoeBottomView">
    <div class="backcolor_box">
      <Ordinary
        :enTitle="enTitle"
        :zhTitle="zhTitle"
        style="padding-top: 59px"
      ></Ordinary>
    </div>
    <div class="bottom_box">
      <div class="store_image_one">
        <img :src="other.store_image_one" class="my_cover" alt="" />
      </div>
      <div class="store_image_tow_box">
        <div class="left">
          <div class="title">{{ other.store_title }}</div>
          <div class="info">{{ other.store_info }}</div>
        </div>
        <div class="right"><img class="my_cover" :src="other.store_image_two" alt="" /></div>
      </div>
    </div>
  </div>
</template>

<script>
import Ordinary from "../../components/ordinary";
export default {
  name: "",
  props: {
    other: {
      type: Object,
    },
  },
  components: { Ordinary },
  data() {
    return {
      zhTitle: "线下体验门店",
      enTitle: "Offline experience store",
    };
  },
};
</script>

<style scoped lang='scss'>
#HmoeBottomView {
  width: 100%;
  .backcolor_box {
    width: 100%;
    height: 301px;
    background: rgba(198, 255, 237, 0.3);
    background-image: url("../../assets/img/home_bottom_back.png");
    background-size: 100% 276px;
    background-position-y: 25px;
    background-repeat: no-repeat;
  }
  .bottom_box {
    width: 1200px;
    height: 735px;
    margin: 0 auto;
    transform: translateY(-116px);
    .store_image_one {
      width: 1200px;
      // width: 62.5vw;
      // height: 320px;
      height: 16.66vw;
      margin: 0 auto;
    }
    .store_image_tow_box {
      width: 100%;
      height: 366px;
      margin-top: 49px;
      display: flex;
      justify-content: space-between;
      .left {
        width: 50%;
        height: 100%;
        .title {
          width: 370px;
          font-size: 30px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #333333;
          margin-left: 172px;
          margin-top: 86px;
        }
        .info {
          width: 370px;
          font-size: 17px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #666666;
          margin-left: 172px;
          line-height: 36px;
          margin-top: 49px;
          white-space: pre-wrap;
        }
      }
      .right {
        width: 50%;
        height: 366px;

      }
    }
  }
}
</style>