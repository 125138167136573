<template>
  <div class="Ordinary">
    <!-- 普通的title  如首页的中英文title -->
    <div class="Ordinary_zh">{{ zhTitle }}</div>
    <div class="Ordinary_en">{{ enTitle }}</div>
  </div>
</template>

<script>
export default {
  name: "",
  props: {
    zhTitle: {
      type: String,
      default: "场景分类",
    },
    enTitle: {
      type: String,
      default: "Scene classification",
    },
  },
};
</script>

<style scoped lang='scss'>
.Ordinary {
  width: 1200px;
  margin: 0 auto;
  height: 76px;
  .Ordinary_zh {
    font-size: 30px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #333333;
    text-align: center;
  }
  .Ordinary_en {
      text-align: center;
    font-size: 30px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: rgba(51,51,51,0.6);
  }
}
</style>