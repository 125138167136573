<template>
  <div id="header">
    <div class="header" :style="background">
      <div class="headerCenter_box">
        <div class="header_logo" @click="tohome">
          <img src="../assets/img/header_logo.png" class="my_cover" alt="" />
        </div>
        <div class="header_menu_box">
          <div class="menu_item" v-for="(item,index) in menuArr" :class="$route.path == item.path || $route.name == item.path ? 'active' : ''" :key="index" @click="topages(item)">{{item.name}}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Header",
  props: {
    text_color: {
      type: Object,
      default: () => {
        return { color: "#fff" };
      },
    },
    background: {
      type: Object,
      default: () => {
        // return { "--background": "transparent" };
        return { "background-color": "#0A513C" };
      },
    },
  },
  components: {},
  data() {
    return {
      menuArr: [
        {
          name: "首页",
          path: "/",
        },
        {
          name: "品牌专区",
          path: "/brandZone",
        },
        {
          name: "睡眠研究院",
          path: "/sleepResearch",
        },
        {
          name: "品牌加盟",
          path: "/brandJoining",
        },
        {
          name: "以旧换新",
          path: "/tradeIn",
        },
        {
          name: "为您服务",
          path: "/service",
        },
      ],
    };
  },
  methods: {
    tohome(){
      if(this.$route.path != '/'){
        this.$router.push('/')
      }
    },
    topages(item){
      if(this.$route.path !== item.path){
        this.$router.push({
          path:item.path,
          query:{}
        })
      }
    }
  },
  watch:{
  }
};
</script>

<style scoped lang='scss'>
.header {
  width: 100%;
  height: 125px;
  .headerCenter_box {
    height: 100%;
    width: 1200px;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .header_logo {
      width: 156px;
      height: 95px;
      margin-left: 42px; ///
    }
    .header_menu_box {
      display: flex;
      .active{
        color: #DCAF59 !important;
      }
      .menu_item {
        font-size: 16px;
        font-family: MicrosoftYaHei;
        line-height: 21px;
        color: #ffffff;
        margin-left: 35px;
      }
      .menu_item:first-child{
        margin-right: 0;
      }
      .menu_item:hover{
        color: #DCAF59;
        cursor: pointer;
      }
    }
  }
}
</style>