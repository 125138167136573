<template>
  <div id="Footer">
    <div class="top_box">
      <div class="center_top">
        <div class="footer_box" @click="GoTop">
          <img src="../assets/img/footer_logo_by.png" class="my_cover" alt="" />
        </div>
        <div class="text_box">
          <div
            class="first_name"
            v-for="(item, index) in footerMenu"
            :key="index"
          >
            {{ item.name }}
            <div class="scend_box">
              <div class="scend_name" v-for="(i, k) in item.child" :key="k">
                {{ i.min_name }}
              </div>
            </div>
          </div>
        </div>
        <div class="line"></div>
        <div class="wxQrcode">
          <div class="top_text">关注我们</div>
          <div class="codeBox">
            <img src="../assets/img/wxQrCode.png" class="my_cover" alt="" />
          </div>
          <div class="bottom_text">打开微信扫一扫</div>
        </div>
      </div>
    </div>
    <div class="bottom_box">
      <div class="center_bottom">
        Copyright © 2021 成都八益家具｜
        <a href="https://beian.miit.gov.cn/#/Integrated/index"
          >蜀ICP备19033690号-2</a
        >
        | <a href=" http://www.langqiyun.com/">浪起科技提供技术支持</a> 
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Footer",
  props: {},
  components: {},
  data() {
    return {
      footerMenu: [
        {
          name: "品牌中心",
          child: [
            {
              min_name: "八益床垫",
              id: "",
              path: "",
            },
            {
              min_name: "八益维宜尔",
              id: "",
              path: "",
            },
            {
              min_name: "八益沙发",
              id: "",
              path: "",
            },
          ],
        },
        {
          name: "售后服务",
          child: [
            {
              min_name: "151-9801-2258",
              id: "",
              path: "",
            },
            {
              min_name: "028-85017113",
              id: "",
              path: "",
            },
          ],
        },
        {
          name: "品牌加盟",
          child: [
            {
              min_name: "品牌优势",
              id: "",
              path: "",
            },
            {
              min_name: "加盟支持",
              id: "",
              path: "",
            },
            {
              min_name: "加盟条件",
              id: "",
              path: "",
            },
            {
              min_name: "加盟流程",
              id: "",
              path: "",
            },
            {
              min_name: "我要加盟",
              id: "",
              path: "",
            },
          ],
        },
        {
          name: "公司邮箱",
          child: [
            {
              min_name: "2470715871@qq.com",
              id: "",
              path: "",
            },
            {
              min_name: "公司网址",
              id: "",
              path: "",
            },
            {
              min_name: "www.wavefound.com",
              id: "",
              path: "",
            },
          ],
        },
      ],
    };
  },
  methods: {
    //点击去页面顶部
    GoTop() {
      (function smoothscroll() {
        var currentScroll =
          document.documentElement.scrollTop || document.body.scrollTop;
        if (currentScroll > 0) {
          window.requestAnimationFrame(smoothscroll);
          window.scrollTo(0, currentScroll - currentScroll / 10);
        }
      })();
    },
  },
  mounted() {},
  watch: {},
  computed: {},
  filters: {},
};
</script>

<style scoped lang='scss'>
#Footer {
  margin: 0;
  padding: 0;
  .top_box {
    width: 100%;
    height: 248px;
    background: #214439;
    .center_top {
      width: 1200px;
      height: 99.5%;
      border-bottom: 1px solid rgba(255, 255, 255, 0.5);
      margin: 0 auto;
      display: flex;
      justify-content: space-between;
      align-items: center;
      .footer_box {
        width: 200px;
        height: 121px;
      }
      .text_box {
        display: flex;
        margin-left: -10px;
        .first_name {
          font-size: 18px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #ffffff;
          // margin-left: 62px;
          margin-left: 50px;
          line-height: 40px;
          .scend_box {
            .scend_name {
              font-size: 14px;
              font-family: Microsoft YaHei;
              font-weight: 400;
              color: #ffffff;
              line-height: 25px;
            }
          }
        }
      }
      .line {
        width: 1px;
        height: 155px;
        background: #ffffff;
        margin-left: 20px;
      }
      .wxQrcode {
        width: 98px;
        text-align: center;
        // height: 169px;
        .top_text {
          font-family: Microsoft YaHei;
          font-weight: 400;
          line-height: 40px;
          color: #ffffff;
        }
        .codeBox {
          width: 96px;
          height: 96px;
        }
        .bottom_text {
          font-size: 14px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          line-height: 24px;
          color: #eaeaea;
        }
      }
    }
  }
  .bottom_box {
    width: 100%;
    height: 52px;
    background: #035a3f;
    .center_bottom {
      margin: 0 auto;
      height: 14px;
      font-size: 12px;
      font-family: SourceHanSansCN-Regular;
      line-height: 52px;
      color: #ffffff;
      text-align: center;
      a {
        color: #ffffff;
        outline: none;
        text-decoration: none;
      }
    }
  }
}
</style>