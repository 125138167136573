import request from "./http.js"


//首页数据
export function getHomeData(){
    return request.post('getHomeData')
}

//获取品牌动态详情
export function getBrandNewsInfo(data){
    return request.post('getBrandNewsInfo',data)
}

//获取视频列表
export function getVideoList(data){
    return request.post('getVideoList',data)
}

//获取视频详情
export function getVideoInfo(data){
    return request.post('getVideoInfo',data)
}

//获取产品列表
export function getProductList(data){
    return request.post('getProductList',data)
}

//获取产品详情
export function getProductInfo(data){
    return request.post('getProductInfo',data)
}

//获取八益·维宜尔产品理念
export function getProductConceptOne(){
    return request.post('getProductConceptOne')
}

//获取八益床垫产品理念
export function getProductConceptTwo(){
    return request.post('getProductConceptTwo')
}

//获取生产车间图片
export function getWorkShop(){
    return request.post('getWorkShop')
}


//获取发展历程
export function getCourse(){
    return request.post('getCourse')
}

//获取社会责任信息
export function getResponsibility(){
    return request.post('getResponsibility')
}


//获取合作伙伴列表
export function getPartnerList(data){
    return request.post('getPartnerList',data)
}


//获取案例列表
export function getCaseList(data){
    return request.post('getCaseList',data)
}


//获取案例详情
export function getCaseInfo(data){
    return request.post('getCaseInfo',data)
}

//获取品牌优势信息
export function getBrandAdvantage(){
    return request.post('getBrandAdvantage')
}

//获取加盟支持信息
export function getFranchiseSupport(){
    return request.post('getFranchiseSupport')
}

//获取加盟条件信息
export function getFranchiseConditions(){
    return request.post('getFranchiseConditions')
}

//提交表单
export function submitForm(data){
    return request.post('submitForm',data)
}


//获取以旧换新页面信息
export function getTradeIn(){
    return request.post('getTradeIn')
}

//获取售后信息和保养知识信息
export function getSales(data){
    return request.post('getSales',data)
}

//获取联系我们信息
export function getContact(){
    return request.post('getContact')
}

//获取门店列表
export function getStoreList(data){
    return request.post('getStoreList',data)
}

//获取省份列表
export function getProvinceList(){
    return request.post('getProvinceList')
}

//获取城市列表
export function getCityList(data){
    return request.post('getCityList',data)
}

//获取验证码图片
export function getCaptcha(){
    var time = Date.parse(new Date());
    return request.get(`getCaptcha?time=${time}`)
}

//床垫查真伪
export function submitSecurityCode(data){
    return request.post('submitSecurityCode',data)
}

//获取banner配置
export function getBanner(){
    return request.post('getBanner')
}

//获取品牌分类
export function getProductGroup(){
    return request.post('getProductGroup')
}

//获取品牌动态列表
export function getBrandNewsList(data){
    return request.post('getBrandNewsList',data)
}


