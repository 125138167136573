import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import 'lib-flexible'
/* 头部组件 */
// import Header from './components/Header'
// Vue.component(Header.name,Header)
/* 尾部组件 */
import Footer from './components/Footer'
Vue.component(Footer.name,Footer)
/*swiper组件*/
import VueAwesomeSwiper from 'vue-awesome-swiper'
import 'swiper/swiper-bundle.css'


import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';

Vue.use(ElementUI);

/* 百度地图组件*/
import BaiduMap from 'vue-baidu-map';
Vue.use(BaiduMap, {
  // ak 是在百度地图开发者平台申请的密钥 详见 http://lbsyun.baidu.com/apiconsole/key */
  ak: '8BB7F0E5C9C77BD6B9B655DB928B74B6E2D838FD'
})


Vue.use(VueAwesomeSwiper)

Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
