<template>
  <div id="app">
    <router-view />
    <Footer></Footer>
  </div>
</template>

<script>
import Footer from "../src/components/Footer";
import { getBanner } from "./http/api";
export default {
  components: { Footer },
  watch: {},
  data() {
    return {
      scroll: 0,
    };
  },
  mounted() {
    getBanner().then((res) => {
      localStorage.setItem('getBanner',JSON.stringify(res.data))
    });
    window.addEventListener("scroll", this.handleScroll);
  },
  methods: {
    handleScroll() {
      this.scroll =
        document.documentElement.scrollTop || document.body.scrollTop;
    },
  },
};
</script>
<style lang="scss">
@import "../src/assets/css/home.css";
* {
  margin: 0;
  padding: 0;
}
#app {
  #BrandZoneList,#VideoList,#BrandList {
    .pagetion_BrandZoneList {
      .el-pagination {
        .el-pagination__jump{
          height: 100%;
          .el-input{
            height: 100%;
            input{
              height: 100%;
              width: 46px;
              border-radius: 0;
            }
          }
        }
        .btn-prev,
        .btn-next {
          height: 46px;
          width: 46px;
          line-height: 46px;
          background: transparent;
          border: 1px solid #e5e5e5;
          color: #666666;
        }
        .el-pager {
          height: 46px;
          .number {
            height: 46px;
            width: 46px;
            line-height: 46px;
            background: transparent;
            border: 1px solid #e5e5e5;
            color: #666666;
          }

          .btn-quicknext,.btn-quickprev {
            height: 46px;
            width: 46px;
            line-height: 46px;
            background: transparent;
            border: 1px solid #e5e5e5;
            color: #666666;
          }
          .active {
            height: 46px;
            width: 46px;
            line-height: 46px;
            background: #035A3F;
            border: 1px solid #e5e5e5;
            color: #fff;
          }
        }
      }
    }
  }
  #case {
    .pagegation {
      .el-pagination {
        .el-pager {
          height: 34px;
          .number {
            height: 34px;
            width: 34px;
            line-height: 34px;
            background: transparent;
            color: #666666;
          }
          .active {
            height: 34px;
            width: 34px;
            line-height: 34px;
            background: #225928;
            color: #fff;
          }
        }
      }
    }
  }
  #home {
    .top_banner_box {
      .swiper-container-vertical > .swiper-pagination-bullets {
        right: 50%;
        top: 95%;
        .swiper-pagination-bullet-active {
          width: 15px;
          height: 15px;
          border: 1px solid #fff !important;
          padding: 2px;
          background: transparent !important;
          position: relative;
          margin: 10px 0;
        }
        .swiper-pagination-bullet-active::after {
          content: "●";
          display: block;
          font-size: 25px;
          color: #c8a86a !important;
          position: absolute;
          top: -9px;
          left: 1.7px;
        }
        .swiper-pagination-bullet {
          width: 15px;
          height: 15px;
          padding: 2px;
          border: none;
          background: transparent;
          border: 1px solid transparent;
          opacity: 1;
          margin: 10px 0;
          position: relative;
        }
        .swiper-pagination-bullet::after {
          content: "●";
          display: block;
          font-size: 25px;
          color: #fff;
          position: absolute;
          top: -9px;
          left: 1.7px;
        }
      }
    }
    .classification_box {
      .classification_center_box {
        .right {
          .swiper-pagination-bullet {
            background: #fff;
            width: 14px;
            height: 14px;
            border-radius: 50% !important;
          }
        }
      }
    }
    .brandDynamics {
      .swiper_my_box {
        .swiper-pagination-bullet {
          width: 14px;
          height: 14px;
          border-radius: 50%;
        }
        .swiper-pagination-bullet-active {
          width: 14px;
          height: 14px;
          background: #035a3f;
          border-radius: 50%;
        }
      }
    }
  }
  #Cooperation,
  #JoinIn {
    .form_box {
      .el-form {
        .el-form-item {
          .el-form-item__label {
            text-align: left !important;
          }
          .el-textarea {
            textarea {
              height: 186px !important;
              max-height: 186px;
            }
          }
        }
      }
    }
  }
  #BrandZoneDetail {
    .detailProduct_box {
      .left_swiper {
        .swiper-container-horizontal
          > .swiper-pagination-bullets
          .swiper-pagination-bullet {
          width: 23px !important;
          height: 8px !important;
          background: rgba(0, 0, 0, 0.4) !important;
          border-radius: 30px !important;
        }
        .swiper-container-horizontal
          > .swiper-pagination-bullets
          .swiper-pagination-bullet-active {
          width: 23px !important;
          height: 8px !important;
          background: #005a3f !important;
          border-radius: 30px !important;
        }
      }
    }
    .size_select_box {
      .el-select {
        height: 100% !important;
        width: 100% !important;
        .el-input {
          height: 100% !important;
          width: 100% !important;
          .el-input__inner {
            height: 100% !important;
            width: 100% !important;
            border-radius: 0 !important;
          }
          .el-input__inner:focus {
            border-color: #c0c4cc !important;
          }
        }
      }
    }
  }
}
</style>
