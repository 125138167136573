<template>
  <div class="spot">
    <!-- 中文两边带点的title组件 -->
    <div class="color_en">{{ SpotEnTitle }}</div>
    <div class="spot_zh">· {{ SpotZhTitle }} ·</div>
  </div>
</template>

<script>
export default {
  name: "",
  props: {
    SpotZhTitle: {
      type: String,
      default: "品牌专区",
    },
    SpotEnTitle: {
      type: String,
      default: "Brand zone",
    },
  },
};
</script>

<style scoped lang='scss'>
.spot {
  // width: 1200px;
  width: 62.5vw;
  margin: 0 auto;
  text-align: center;
  .color_en {
    font-size: 24px;
    font-family: MicrosoftYaHei;
    line-height: 21px;
    color: #784c0c;
  }
  .spot_zh {
    font-size: 30px;
    font-family: MicrosoftYaHei;
    line-height: 40px;
    color: #3a3939;
  }
}
</style>