<template>
  <div id="list">
    <div class="List">
      <div
        @click="todetail(item)"
        v-for="(item, i) in carList"
        :key="i"
        class="FadeOu"
        ref="fadeOut"
      >
        <img
          v-if="types == 1"
          class="jiantou"
          src="../assets/img/list_right.png"
        />
        <div class="Meng" v-if="types == 1"></div>
        <div class="center_list_item">
          <div class="img">
            <img class="my_cover" :src="item.cover" alt="" />
          </div>
          <div class="title">{{ item.title }}</div>
          <div class="info">{{ item.info }}</div>
          <div class="line"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    carList: {
      type: Array,
    },
    types: {
      type: Number | String,
      default: 1,
    },
  },
  data() {
    return {
      arr: [],
      topnum: 0,
      offsetTop: 0,
    };
  },
  mounted() {
    window.addEventListener("scroll", this.handleScroll);
  },
  components: {},
  methods: {
    todetail(item) {
      console.log(item, this.types);
      if (this.types == 1) {
        this.$router.push({
          path: "/VideoDetail",
          query: {
            id: item.id,
          },
        });
      } else {
        this.$router.push({
          path: "/BrandDetail",
          query: {
            id: item.id,
          },
        });
      }
    },
  },
};
</script>

<style scoped lang="scss">
#list {
  width: 100%;
  .List {
    margin: 0 auto;
    width: 1200px;
    display: flex;
    flex-wrap: wrap;
    .FadeOu:hover {
      .jiantou {
        transition: all 0.3s linear;
        position: absolute;
        top: 25%;
        left: 43%;
        z-index: 9999;
      }
      .Meng {
        transition: all 0.3s linear;
        background: rgba(0, 0, 0, 0.3);
      }
      .line {
        width: 380px;
        transition: all 0.3s;
      }
    }
    .FadeOu {
      width: 380px;
      height: 374px;
      box-shadow: 0px 4px 10px 0px rgba(40, 40, 40, 0.19);
      background: #fff;
      margin-right: 27px;
      position: relative;
      overflow: hidden;
      margin-bottom: 65px;
      .jiantou {
        transition: all 0.3s linear;
        width: 48px;
        height: 27px;
        display: block;
        position: absolute;
        top: -30px;
        left: 43%;
        z-index: 9999;
      }
      .Meng {
        width: 100%;
        transition: all 0.3s linear;
        height: 227px;
        background: transparent;
        position: absolute;
        top: 0;
      }
      .center_list_item {
        margin: 9px;
        .img {
          width: 361px;
          height: 207px;
        }
        .title {
          width: 341px;
          font-size: 19px;
          font-family: PingFang SC;
          font-weight: 500;
          color: #333333;
          margin: 41px 0 24px 0;
        }
        .info {
          width: 341px;
          font-size: 13px;
          font-family: PingFang SC;
          font-weight: 500;
          color: #333333;
          text-overflow: -o-ellipsis-lastline;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          line-clamp: 2;
          -webkit-box-orient: vertical;
        }
      }
      .line {
        position: absolute;
        width: 0;
        height: 2px;
        bottom: 0;
        left: 0;
        background-color: #ffe62c;
      }
    }
    .FadeOu:nth-child(3n) {
      margin-right: 0;
    }
  }
}
</style>
