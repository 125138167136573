import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home/Home.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: '八益家具',
    component: Home,
    meta: {
      title: '八益家具'
    }
  },
  {
    path: '/videoList',
    name: '/',
    component: () => import('../views/Video/VideoList.vue'),
    meta: {
      title: '视频列表'
    }
  },
  {
    path: '/BrandList',
    name: '/',
    component: () => import('../views/BrandList/BrandList.vue'),
    meta: {
      title: '品牌列表'
    }
  },
  {
    path: '/BrandDetail',
    name: '/',
    component: () => import('../views/BrandList/BrandDetail.vue'),
    meta: {
      title: '品牌动态详情'
    }
  },
  {
    path:'/VideoDetail',
    name: '/',
    component: () => import('../views/Video/VideoDetail.vue'),
    meta: {
      title: '视频列表'
    }
  },
  {
    path: '/brandZone',
    name: '品牌专区',
    component: () => import('../views/BrandZone/BrandZone.vue'),
    meta: {
      title: '品牌专区'
    },
  },
  {
    path: '/BrandZoneList',
    name: '/brandZone',
    component: () => import('../views/BrandZone/BrandZoneList.vue'),
  },
  {
    path: '/BrandZoneDetail',
    name: '/brandZone',
    component: () => import('../views/BrandZone/BrandZoneDetail.vue'),
  },
  {
    path: '/sleepResearch',
    name: '睡眠研究院',
    component: () => import('../views/SleepResearch/SleepResearch.vue'),
    meta: {
      title: '睡眠研究院'
    }
  },
  {
    path: '/brandJoining',
    name: '品牌加盟',
    component: () => import('../views/BrandJoining/BrandJoining.vue'),
    meta: {
      title: '品牌加盟'
    },
  },
  {
    path: '/tradeIn',
    name: '以旧换新',
    component: () => import('../views/TradeIn/TradeIn.vue'),
    meta: {
      title: '以旧换新'
    }
  },
  {
    path: '/service',
    name: '为您服务',
    component: () => import('../views/Service/Service.vue'),
    meta: {
      title: '为您服务'
    }
  },

]




const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.afterEach((to, from, next) => {
  window.scrollTo(0, 0)
})

export default router
